import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DptAmcOption, DptFakOverrideOption, DptOption } from '@xpo-ltl/sdk-dynamicpricing';
import { LookupService } from 'src/app/_services/lookup.service';
import { OnSaveEvent, SaveEvent, TabStatus } from '../../../rfp-edit.component';
import { RfpDetails } from 'src/app/_models/temp/rfpDetails';
import { RfpFakOverride } from '../rfp-fak-overrides/fakClassOverride';
import { Observable, forkJoin } from 'rxjs';
import { RfpAccessorial } from 'src/app/_models/rfp/rfpAccessoral';
import { RfpAmcOverride } from 'src/app/_models/rfp/rfpAmcOverride';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/_services/utility.service';
import { MetricFormat } from 'src/app/_models/enums';
import { RfpTariff } from 'src/app/_models/rfp/rfpTariff';
import { DptOptionCodeDisplay } from 'src/app/_models/temp/dptConstants';
import { RfpService } from 'src/app/_services/rfp.service';

export enum DptFormFields {
  DiscountTypeCode = "dynamicPricingPeriod",
  DynamicDiscountPercent = "dynamicDiscountPercent",
  RateTariffDynamic = "rateTariffDynamic",
  IsStandardAccessorials = "isStandardAccAmc",
  AccessorialFormArray = "accessorialFormArray",
  AccessorialCode = "accessorial",
  AccessorialAmount = "accessorialAmount",
  IsStandardAmc = "isStandardAmc",
  AmcFormArray = "amcFormArray",
  AmcCode = "amc",
  AmcAmount = "amcAmount",
  IsNoFakOverride = "isFakOverride",
  FakOverrideFormArray = "fakOverrideFormArray",
  FakOverrideField = "fakOverrideField",
}

@Component({
  selector: 'rfp-dpt',
  templateUrl: './rfp-dpt.component.html',
  styleUrls: ['./rfp-dpt.component.scss']
})
export class RfpDptComponent implements OnInit, OnSaveEvent {
  @Input() rfpDetails: RfpDetails;
  @Output() rfpSave = new EventEmitter<SaveEvent>();

  metricFormat = MetricFormat;
  dptFormFields = DptFormFields;
  dptFormGroup: UntypedFormGroup;

  accessorialOptions: DptOption[];
  amcOptions: DptAmcOption[];
  discountTypeOptions: DptOption[];
  fakOptions: DptFakOverrideOption[];

  constructor(
    public formBuilder: UntypedFormBuilder, 
    public lookup: LookupService,
    private rfpService: RfpService,
  ) { }

  ngOnInit(): void {
    forkJoin([this.lookup.dptAccessorialTypes$, this.lookup.dptAmcTypes$, this.lookup.dptDiscountTypes$, this.lookup.dptFakOverrideTypes$]).subscribe(response => {
      this.accessorialOptions = response[0];
      this.amcOptions = response[1];
      this.discountTypeOptions = response[2];
      this.fakOptions = response[3];
      this.createForm();
    })

  }
  
  ngOnChanges(changes: SimpleChanges){
    if(changes.rfpDetails && !changes.rfpDetails.firstChange) {
      this.createForm();
    }
  }

  isChangeAccessorialRfp(): boolean {
    return this.rfpDetails?.rfpTypeCode === "ChangeAc"
  }

  createForm(){
    this.dptFormGroup = this.formBuilder.group({
      [DptFormFields.DiscountTypeCode]:       [this.rfpDetails.dynamicPricingPeriod, Validators.required],
      [DptFormFields.DynamicDiscountPercent]: [this.rfpDetails.dynamicDiscountPercent, Validators.required],
      [DptFormFields.RateTariffDynamic]:      [this.rfpDetails.rateTariffDynamic?.tariffCode ?? "CTS599", Validators.required],
      [DptFormFields.IsStandardAccessorials]: [],
      [DptFormFields.AccessorialFormArray]:   this.initFormArray<RfpAccessorial>(this.rfpDetails.accessorials, this.createAccessorialFormGroup.bind(this)),
      [DptFormFields.IsStandardAmc]:          [],
      [DptFormFields.AmcFormArray]:           this.initFormArray<RfpAmcOverride>(this.rfpDetails.amcOverrides.map(x => new RfpAmcOverride(x)), this.createAmcFormGroup.bind(this)),
      [DptFormFields.IsNoFakOverride]:        [],
      [DptFormFields.FakOverrideFormArray]:   this.initFormArray<RfpFakOverride>(this.rfpDetails.fakOverrides, this.createFakOverrideFormGroup.bind(this)),
    }, { validators: [this.ValidateValueRange(DptFormFields.DiscountTypeCode, DptFormFields.DynamicDiscountPercent, this.discountTypeOptions)] });
    
    
    this.setupFlagControl(DptFormFields.IsStandardAccessorials, DptFormFields.AccessorialFormArray, this.rfpDetails.accessorials.length === 0)
    this.setupFlagControl(DptFormFields.IsStandardAmc, DptFormFields.AmcFormArray, this.rfpDetails.amcOverrides.length === 0)
    this.setupFlagControl(DptFormFields.IsNoFakOverride, DptFormFields.FakOverrideFormArray, this.rfpDetails.fakOverrides.length === 0)

    this.dptFormGroup.get(DptFormFields.IsNoFakOverride).valueChanges.subscribe(newValue => {
      const currentDiscountTypeCode: string = this.dptFormGroup.get(DptFormFields.DiscountTypeCode).value
      if(currentDiscountTypeCode){
        this.lookup.dptDiscountTypes$.subscribe(discountOptions => {
        
          let newDiscount;
          const isStandard = currentDiscountTypeCode.toLowerCase().includes("standard");
          const isQuarterly = currentDiscountTypeCode.toLowerCase().includes("quarterly");
          
          if(isStandard){
            newDiscount = discountOptions.find(type => newValue ? type.code.toLowerCase().includes("standard") : type.code.toLowerCase().includes("standard") && type.code.toLowerCase().includes("fak"))
          }
          else if (isQuarterly){
            newDiscount = discountOptions.find(type => newValue ? type.code.toLowerCase().includes("quarterly") : type.code.toLowerCase().includes("quarterly") && type.code.toLowerCase().includes("fak"))
          }          
          this.dptFormGroup.get(DptFormFields.DiscountTypeCode).setValue(newDiscount.code)
        })
      }
    })

    setTimeout(() => {
      this.handleEditability();
    }, 0);
  }

  getTabStatus(): TabStatus {
    return { 
      complete: this.dptFormGroup?.enabled ? this.dptFormGroup?.valid : true, 
      canSave: this.dptFormGroup?.enabled ? this.dptFormGroup?.valid : false, 
      unsavedChanges: this.dptFormGroup?.dirty, //UtilityService.isFormGroupDirty(this.dptFormGroup),
      tabName: "General"
    };
  }

  onRfpSave(): Observable<SaveEvent> {
    return forkJoin([this.lookup.dptAccessorialTypes$, this.lookup.dptAmcTypes$, this.lookup.dptFakOverrideTypes$, this.lookup.dptRateTariffTypes$, this.lookup.rfpTariffs$]).pipe(
      map(lookups => {
        const accessorials = lookups[0];
        const amcs = lookups[1];
        const faks = lookups[2];
        const dptTariffs = lookups[3];
        const smc3Tariffs = lookups[4];

        let saveEvent: SaveEvent  = {
          changes: []
        }

        if(!this.isChangeAccessorialRfp()){
          saveEvent.changes.push({ key: DptFormFields.DiscountTypeCode,       value: this.dptFormGroup.get(DptFormFields.DiscountTypeCode).value })
          saveEvent.changes.push({ key: DptFormFields.DynamicDiscountPercent, value: this.dptFormGroup.get(DptFormFields.DynamicDiscountPercent).value })
          saveEvent.changes.push({ key: DptFormFields.RateTariffDynamic,      value: this.getRateTariffSave(dptTariffs, smc3Tariffs) })
          saveEvent.changes.push({ key: "fakOverrides",                       value: this.getFakSave(faks) })
          saveEvent.changes.push({ key: "amcOverrides",                       value: this.getAmcSave(amcs) })
        }

        saveEvent.changes.push({ key: "accessorials",                       value: this.getAccessorialSave(accessorials) })

        return saveEvent;
      })
    );
  }

  getDefaultValue(option: DptOption, format: MetricFormat){
    if(!option) return null;

    let value = option.value
    if(format === MetricFormat.Percent) value /= 100

    return `Default: ${UtilityService.formatValue(value, format)}`
  }

  isDptOptionSelected(option: DptOption, formArrayName: string, codeKey: string): boolean{
    const selectedCodes = (this.dptFormGroup.get(formArrayName)as UntypedFormArray).controls.map((formGroup: UntypedFormGroup) => {
      return formGroup.get(codeKey).value
    }).filter(x => x != null)
    
    return selectedCodes.includes(option.code)
  }

  getRateTariffSave(dptTariffs: DptOptionCodeDisplay[], smc3Tariffs: RfpTariff[]){
    const dptTariff = dptTariffs.find(x => x.code === this.dptFormGroup.get(DptFormFields.RateTariffDynamic).value)

    let effectiveDate: string = smc3Tariffs.filter(x => x.tariffCode.includes(dptTariff.code)).reduce((latest, current) => {
      let latestDate = new Date(latest.effectiveDate);
      let currentDate = new Date(current.effectiveDate);
      return latestDate > currentDate ? latest : current;
    })?.effectiveDate

    const tariff: RfpTariff = {
      tariffCode: dptTariff.code,
      tariffName: dptTariff.display,
      effectiveDate: effectiveDate,
    }
    return tariff;
  }

  getAccessorialSave(accessorialOptions: DptOption[]){
    if(this.dptFormGroup.get(DptFormFields.IsStandardAccessorials).value) return []

    return (this.dptFormGroup.get(DptFormFields.AccessorialFormArray) as UntypedFormArray).controls.map((x: UntypedFormGroup)=> {
      const selectedAccessorialCode: string = x.get(DptFormFields.AccessorialCode).value
      return {
        accessorialCode: selectedAccessorialCode,
        accessorialName: accessorialOptions.find(x => x.code === selectedAccessorialCode).display,
        amount: x.get(DptFormFields.AccessorialAmount).value,
      }
    }) 
  }

  getAmcSave(amcOptions: DptAmcOption[]){
    if(this.dptFormGroup.get(DptFormFields.IsStandardAmc).value) return []

    return (this.dptFormGroup.get(DptFormFields.AmcFormArray) as UntypedFormArray).controls.map((x: UntypedFormGroup)=> {
      const selectedAmcCode: string = x.get(DptFormFields.AmcCode).value
      const selectedAmc: DptAmcOption = amcOptions.find(option => option.code === selectedAmcCode);
      const amc = {
        typeCode: selectedAmc.code,
        chargeAmount: x.get(DptFormFields.AmcAmount).value,
      }

      if(selectedAmc.milesInd){
        return {
          ...amc,
          minimumMiles: selectedAmc.minMilesNbr,
          maximumMiles: selectedAmc.maxMilesNbr
        }
      }

      return amc
    }) 
  }

  getFakSave(faks: DptFakOverrideOption[]){
    if(this.dptFormGroup.get(DptFormFields.IsNoFakOverride).value) return []

    return (this.dptFormGroup.get(DptFormFields.FakOverrideFormArray) as UntypedFormArray).controls.map((x: UntypedFormGroup)=> {
      const selectedFak: DptFakOverrideOption = faks.find(fak => fak.overrideClass === x.get(DptFormFields.FakOverrideField).value)

      const override: RfpFakOverride = {
        classCode:        selectedFak.overrideClass,
        fromClassCode:    selectedFak.fromClass,
        throughClassCode: selectedFak.throughClass
      }
      return override 
    })
  }

  onRfpSaveSuccess(): void {    
    this.dptFormGroup.markAsPristine();
    this.handleEditability();
  }

  handleEditability(): void {
    this.rfpService.isEditable() ? this.dptFormGroup.enable() : this.dptFormGroup.disable();
    this.setFormEnabled(DptFormFields.FakOverrideFormArray, this.rfpService.isEditable() && !this.dptFormGroup.get(DptFormFields.IsNoFakOverride).value)
    this.setFormEnabled(DptFormFields.AccessorialFormArray, this.rfpService.isEditable() && !this.dptFormGroup.get(DptFormFields.IsStandardAccessorials).value)
    this.setFormEnabled(DptFormFields.AmcFormArray, this.rfpService.isEditable() && !this.dptFormGroup.get(DptFormFields.IsStandardAmc).value)

    if(this.isChangeAccessorialRfp()){
      this.dptFormGroup.get(DptFormFields.DiscountTypeCode).disable();
      this.dptFormGroup.get(DptFormFields.DynamicDiscountPercent).disable();
      this.dptFormGroup.get(DptFormFields.RateTariffDynamic).disable();
      this.dptFormGroup.get(DptFormFields.FakOverrideFormArray).disable();
      this.dptFormGroup.get(DptFormFields.AmcFormArray).disable();
    }
  }

  setupFlagControl(flagControlName: string, arrayName: string, initialValue: boolean = true){
    let flagControl = this.dptFormGroup.get(flagControlName)
    flagControl.valueChanges.subscribe(newValue => {
      this.setFormEnabled(arrayName, !newValue)
    })
    flagControl.setValue(initialValue)
  }

  initFormArray<T>(selected: T[], createFunction: Function): UntypedFormArray{
    let arrayEntries: UntypedFormGroup[] = []
    
    if(selected && selected.length > 0){
      arrayEntries = selected.map(x => createFunction(x))
    }
    else {
      arrayEntries.push(createFunction());
    }

    return this.formBuilder.array(arrayEntries);
  }

  createAccessorialFormGroup(accessorial?: RfpAccessorial): UntypedFormGroup {
    return this.formBuilder.group({
      [DptFormFields.AccessorialCode]:    [accessorial?.accessorialCode, Validators.required],
      [DptFormFields.AccessorialAmount]:  [accessorial?.amount, Validators.required],
    }, { validators: [this.ValidateValueRange(DptFormFields.AccessorialCode, DptFormFields.AccessorialAmount, this.accessorialOptions)] });
  }

  createAmcFormGroup(amc?: RfpAmcOverride): UntypedFormGroup {
    return this.formBuilder.group({
      [DptFormFields.AmcCode]:    [amc?.typeCode, Validators.required],
      [DptFormFields.AmcAmount]:  [amc?.chargeAmount, Validators.required],
    }, { validators: [this.ValidateValueRange(DptFormFields.AmcCode, DptFormFields.AmcAmount, this.amcOptions)] });
  }

  createFakOverrideFormGroup(fakOverride?: RfpFakOverride): UntypedFormGroup {
    return this.formBuilder.group({
      [DptFormFields.FakOverrideField]: [fakOverride?.classCode, Validators.required],
    });
  }

  getFormArrayControlByName(formField: DptFormFields): UntypedFormGroup[]{
    return ((this.dptFormGroup.get(formField) as UntypedFormArray).controls as UntypedFormGroup[]);
  }

  canAddAccessorialOverride(): boolean {
    return !this.dptFormGroup.get(DptFormFields.IsStandardAccessorials).value && this.dptFormGroup.get(DptFormFields.AccessorialFormArray).enabled
  }

  addAccessorial(): void {
    if(this.canAddAccessorialOverride()){
      const formArray: UntypedFormArray = (this.dptFormGroup.get(DptFormFields.AccessorialFormArray) as UntypedFormArray)
      formArray.push(this.createAccessorialFormGroup());
      formArray.markAsDirty();
    }
  }

  canAddAmcOverride(): boolean {
    return !this.dptFormGroup.get(DptFormFields.IsStandardAmc).value && this.dptFormGroup.get(DptFormFields.AmcFormArray).enabled
  }

  addAmc(): void {
    if(this.canAddAmcOverride()){
      const formArray: UntypedFormArray = (this.dptFormGroup.get(DptFormFields.AmcFormArray) as UntypedFormArray);
      formArray.push(this.createAmcFormGroup());
      formArray.markAsDirty();
    }
  }

  canAddFakOverride(): boolean {
    return !this.dptFormGroup.get(DptFormFields.IsNoFakOverride).value && this.dptFormGroup.get(DptFormFields.FakOverrideFormArray).enabled
  }

  addFakOverride(): void {
    if(this.canAddFakOverride()){
      const formArray: UntypedFormArray = (this.dptFormGroup.get(DptFormFields.FakOverrideFormArray) as UntypedFormArray)
      formArray.push(this.createFakOverrideFormGroup());
      formArray.markAsDirty();
    }
  }

  fakCheckAvailableOption(fakOverride: DptFakOverrideOption): Observable<boolean>  {
    return this.lookup.dptFakOverrideTypes$.pipe(map(overrides => {
      if (!fakOverride) return false;
      const overrideClassCodes: string[] = (this.dptFormGroup.get(DptFormFields.FakOverrideFormArray) as UntypedFormArray).value.map(item => item[DptFormFields.FakOverrideField]).filter(x => x != null);
      let currentOverrides = overrides.filter(x => overrideClassCodes.includes(x.overrideClass))
      // Convert the fakOverride's classes to numbers for proper comparison
      let fakFrom = Number(fakOverride.fromClass);
      let fakThrough = Number(fakOverride.throughClass);

      // return false if there's an overlap, true otherwise
      return !currentOverrides.some(existingOverride => {
        // Convert existing classes to numbers
        let existingFrom = Number(existingOverride.fromClass);
        let existingThrough = Number(existingOverride.throughClass);

        // Check if the fakOverride range overlaps with the existingOverride range
        return (
          (fakFrom >= existingFrom && fakFrom <= existingThrough) ||
          (fakThrough >= existingFrom && fakThrough <= existingThrough) ||
          (fakFrom <= existingFrom && fakThrough >= existingThrough)
        );
      });
    }))
  }

  resetEntry(formArrayName: string, index: number): void {
    const formArray = this.dptFormGroup.get(formArrayName) as UntypedFormArray;
    formArray.at(index).reset();
  }

  deleteEntry(formArrayName: string, index: number): void {
    const formArray = this.dptFormGroup.get(formArrayName) as UntypedFormArray;
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  setFormEnabled(formArrayName: string, enabled: boolean){
    const formArray = this.dptFormGroup.get(formArrayName) as UntypedFormArray;
    if(enabled && this.rfpService.isEditable()) formArray.enable()
    else formArray.disable();
  }

  showOutOfRangeWarning(dptOption: DptOption, currentValue: number): boolean {
    if(!dptOption || Number.isNaN(currentValue)) return false;
    return (currentValue < dptOption.min || currentValue > dptOption.max);
  }

  getDptOptionFromKey(list: DptOption[], code: string){
    if(!list) return null
    return list.find(x => x.code === code)
  }

  checkDiscountType(option: DptOption): boolean {
    const isNoFakOverride: boolean = this.dptFormGroup.get(DptFormFields.IsNoFakOverride).value;
    return isNoFakOverride ? option.code.toLowerCase().includes("fak") : !option.code.toLowerCase().includes("fak");
  }

  
  // VALIDATORS
  ValidateValueRange(optionFormControlName: string, valueFormControlName: string, options: DptOption[]): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const optionFormControl = formGroup.get(optionFormControlName);
      const valueFormControl = formGroup.get(valueFormControlName);

      if(optionFormControl?.enabled && valueFormControl?.enabled){
        const selectedOption: DptOption = options.find(x => x.code === optionFormControl.value);
        const enteredValue: number = parseFloat(valueFormControl.value);
        
        if(selectedOption){
          if(this.showOutOfRangeWarning(selectedOption, enteredValue)){
            return {
              enteredValueOutOfRange: true
            }
          }
        }
      }
      return null
    }
  }
}